import React from 'react';
import apple from '../../src/images/apple-podcast.png';
import spotify from '../../src/images/spotify-icon.png';
import stitcher from '../../src/images/stitcher-icon.png';
import google from '../../src/images/google-podcasts.svg';
import '../../src/styles/main.css';
import { Link } from "react-scroll";

const MainPage = () => {
    return (
        <>
            <div className="row" id="main">
                <div className="blockDiv">
                    <div className="jumbotron mx-2 title text-center">
                        <p className="display-5 text-center px-5 title-text"> Matching podcasters with brands that fit their niche. </p>
                        <p className="h5 gray px-5"> Podcast monetization without the expensive podcast network. Match with sponsors and brand affiliations that best fit your pod’s niche, audience, and size. Build your network, monitor your campaigns, and get paid. </p>
                        <div className='pt-3'>
                            <img src={ apple } alt="Apple Podcasts" width="50" height="50" className='mx-2' />
                            <img src={ spotify } alt="Spotify Podcasts" width="50" height="50" className='mx-2' />
                            <img src={ stitcher } alt="Stitcher Podcasts" width="50" height="50" className='mx-2'/>
                            <img src={ google } alt="Google Podcasts" width="50" height="50" className='mx-2'/>
                        </div>
                    </div>
                    <div className="col text-center pb-4">
                        <Link to="signup" href="signup">
                            <button className="btn join-btn text-white mt-5" style={{backgroundColor: "#4b9cd3"}}>Join the Waitlist</button>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainPage;