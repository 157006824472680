import "./App.css";
import { useState, useEffect, React } from "react";
// import './styles/main.css';
import Header from "./components/Header";
import MainAppDisplay from "./components/MainAppDisplay";
import Blog from "./components/Blog";
import CookiesPolicy from "./terms/CookiesPolicy"
import { Route, Routes } from "react-router-dom";
import { MakeMoneyPodcasting } from "./components/BlogPosts/MakeMoneyPodcasting";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { useCookies } from "react-cookie";
import Terms from "./terms/Terms";
import { BookingGuests } from "./components/BlogPosts/BookingGuests";

function App() {
  const [shouldShowModal, setShouldShowModal] = useState(true);
  const [cookies, setCookies] = useCookies(["showCurtainModal"]);
  const [data, setData] = useState({
    email: null,
  });
  useEffect(() => {
    if (cookies.showCurtainModal === "false") {
      setShouldShowModal(false);
    }
  });

  function handleCloseOrSubmit() {
    var date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    setShouldShowModal(false);
    setCookies("showCurtainModal", "false", { path: "/", expires: date });
  }

  function handleEmail(e) {
    setData({ ...data, email: e.target.value });
  }

  async function handleSubmit(e) {
    e.preventDefault();
    await fetch("/submitUser", {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
    }).then(() => {
      console.log("Email sent");
    });
    handleCloseOrSubmit();
  }
  return (
    <>
      <Header />
      <Modal show={shouldShowModal} backdrop="static">
        <Modal.Body style={{ paddingTop: "4vh" }}>
          <p className="h4 text-center">
            Get <i>FREE</i> access to our list of affiliate
            marketing programs!
          </p>
          <p className="pt-3">
            {" "}
            We'll send you a link to our growing list of affiliate
            marketing programs. The list
            contains affiliate marketing programs broken down by category and
            profitability.
          </p>
          <form className="pt-3" onSubmit={handleSubmit}>
            <div className="form-group align-items-center text-center">
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                onChange={(event) => handleEmail(event)}
                aria-describedby="emailHelp"
                placeholder="Enter email"
              />
              <small id="emailHelp" className="form-text text-muted">
                By providing your email you accept to receive all emails from
                Curtain.
              </small>
              <button type="submit" className="btn btn-primary mt-2">
                Get my access link
              </button>
              <br />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="">
          <Button
            variant="secondary-outline"
            onClick={() => handleCloseOrSubmit()}
            size="sm"
          >
            No thanks
          </Button>
        </Modal.Footer>
      </Modal>
      <Routes>
        <Route path="/" element={<MainAppDisplay />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/cookies" element={<CookiesPolicy />} />
        <Route
          path="/blog/finding-guests"
          element={<BookingGuests />}
        />
        <Route
          path="/blog/make-money-podcasting"
          element={<MakeMoneyPodcasting />}
        />
      </Routes>
    </>
  );
}

export default App;
