import React from "react";
import '../../src/styles/main.css';
import Money from '../images/money.png'
import Guest from "../images/podcastguest.jpg";
import { ArrowLeftIcon } from '@primer/octicons-react';
import { Link } from 'react-router-dom';
import Footer from './Footer';

const Blog = () => {

        return (
            <>
                <div className="fadein">
                    <div className="row justify-content-center pt-3 pb-5 card-group" style={{background: "#F6F6F2", height: "100vh"}}>
                        <div className="row justify-content-center padding-top">
                            <div className="col-1 d-flex align-items-center justify-content-left">
                                <Link to="/" style={{texDecoration: 'none !important', color: "grey"}}><ArrowLeftIcon size={35} className="" /></Link>
                            </div>
                            <div className="col-9 d-flex align-items-center">
                                <p className="display-4 ari text-dark mb-0">Blog</p>
                            </div>
                            <div className="col-auto"></div>
                        </div>
                        <div className="col-md-auto"></div>
                        <div className="col-lg-6 col-md-10 gx-md-4">
                            <div className="card-group">
                                <div className="card shadow mx-2">
                                    <Link to="/blog/make-money-podcasting">
                                        <img className="card-img-top align-self-center" style={{ height: "35vh"}} src={Money} alt="Make Money Podcasting"/>
                                    </Link>
                                    <div className="card-body">
                                        <Link to="/blog/make-money-podcasting" style={{textDecoration: 'none'}}>
                                            <h5 className="card-title text-dark">Top 4 Ways To Make Money Podcasting</h5>
                                        </Link>
                                        <p className="card-text text-muted">Learn the four most popular strategies podcast creators use to make money off their podcasts.</p>
                                    </div>
                                    <div className="card-footer d-flex">
                                        <small className="text-muted flex-fill">Created August 18th, 2022</small>
                                        <small className="text-secondary justify-content-end">Justin Walker</small>
                                    </div>
                                </div>
                                <div className="card shadow mx-2">
                                    <Link to="/blog/finding-guests">
                                        <img className="card-img-top align-self-center" style={{ height: "35vh"}} src={Guest} alt="Finding Guests"/>
                                    </Link>
                                    <div className="card-body">
                                        <Link to="/blog/finding-guests" style={{textDecoration: 'none'}}>
                                            <h5 className="card-title text-dark">A Guide to Finding and Booking Guests (And the Best Tools to Use 👀)</h5>
                                        </Link>
                                        <p className="card-text text-muted">A guide on how and where to find interesting guests for your podcast. </p>
                                    </div>
                                    <div className="card-footer d-flex">
                                        <small className="text-muted flex-fill">Created Oct. 22nd, 2022</small>
                                        <small className="text-secondary justify-content-end">Justin Walker</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-auto"></div>
                    </div>
                </div>
                <Footer />
            </>
    )
}

export default Blog;