import React, { useEffect, useState } from "react";
import { Nav, Navbar} from 'react-bootstrap';
import Curtain from '../../src/images/curtainname.png';
import '../../src/styles/main.css'
import { Link } from "react-scroll";
import { Link as Linkk, useLocation} from 'react-router-dom';

const Header = () => {
        const [navWhite, setNavWhite] = useState(false);
        const location = useLocation();
        useEffect(() => {
            const onScroll = () => {
                if(window.pageYOffset > 10) {
                    setNavWhite(true);
                } else {
                    setNavWhite(false);
                }
            }
            window.addEventListener('scroll', onScroll);
        });

        let conditionalHomeButton
        
        if (location.pathname.includes("/blog")) {
            conditionalHomeButton = <Linkk to="/" className=" px-4 pt-2 navItems align-self-center">Home</Linkk> 
        }

        return (
            <>
                <Navbar collapseOnSelect expand="lg" fixed="top" className={navWhite ? "topnav" : "nocolor"}>
                    <Navbar.Brand className="d-flex brand" style={{paddingLeft: "15px"}}>
                    { location.pathname.split("/")[1].length > 0 ?
                        <Linkk to="/">
                            <img src={ Curtain } alt="curtain" width="150" height="32" className="img-fluid d-flex" />
                        </Linkk>
                        :
                        <Link to="main" href="home">
                            <img src={ Curtain } alt="curtain" width="150" height="32" className="img-fluid d-flex" />
                        </Link>
                    }
                    
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" className="rightPad"/>
                    <Navbar.Collapse className="justify-content-center transparentBackground">
                        <Nav className="col-lg-12 justify-content-end">
                            {conditionalHomeButton}
                            <Linkk to="blog" className="px-4 pt-2 navItems align-self-center">Blog</Linkk>
                            { location.pathname.includes("/blog") ?
                                <a href="/#signup" className="px-4 pr-5 pt-2 navItems align-self-center">Join the Waitlist</a>
                                :
                                <Link to="signup" href="signup" className="px-4 pr-5 pt-2 navItems align-self-center">Join the Waitlist</Link>
                            }
                            </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </>
        )
}


export default Header;