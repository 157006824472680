import React from 'react';
import CoreFeatures from './CoreFeatures';
import Main from './Main';
import WhoWeAre from './WhoWeAre';
import SignUp from './SignUp';
import Footer from './Footer'

function MainAppDisplay() {
    return (
      <>
        <Main />
        <WhoWeAre />
        <CoreFeatures />
        <SignUp />
        <Footer />
      </>     
    )
  }

export default MainAppDisplay;