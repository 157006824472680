import React from "react";
import Guest from "../../images/podcastguest.jpg";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@primer/octicons-react";
import Footer from '../Footer';

export function BookingGuests() {
    return (
        <>
        <div className="fadein" style={{background: "#F6F6F2"}}>
            <div className="row mt-5 pt-5 text-center">
                <div className="col-1">
                    <Link to="/blog" style={{texDecoration: 'none !important', color: "grey"}} className="px-5"><ArrowLeftIcon size={35} className="" /></Link>
                </div>
                <div className="col-10">
                    <p className="display-5 text-dark">A Guide to Finding and Booking Guests <br /> (And the Best Tools to Use 👀)</p>
                </div>
                <div className="col-1"></div>
            </div>
            <div className="row pt-1">
                <div className="col-md-2"></div>
                <div className="col-md-8 d-flex align-items-end justify-content-end" style={{backgroundImage: `url(${Guest})`, backgroundPosition: 'center', backgroundSize: 'cover', height:'60vh', backgroundRepeat: 'no-repeat'}}>
                <p className="px-2 text-light bg-dark"> Justin Walker </p>
                <p className="px-2 text-light bg-dark"> October 22nd, 2022</p>
                </div>
                <div className="col-md-2"></div>
            </div>
            <div className="row" style={{background: "#F6F6F2"}}>
            <div className="col-2"></div>
            <div className={window.screen.width < 900 ? "col-lg-8 col-md-8 pt-4 mx-3": "col-lg-8 col-md-8 pt-4"}>
                <p className="h3 pb-2">"Should I Book Guests on My Podcast?"</p>
                <p className="text-dark lh-lg"> 
                    If you are a podcast creator, chances are at some point you will need to begin finding and booking guests for your podcast.
                    If you don't think you will need guests on your podcast, just remember your audience expects <i>new</i> and <i>interesting</i> content 
                    in every episode you drop. So, in order to keep them engaged and interested, you will either need to spend a significant amount of 
                    time learning and brainstorming new and interesting stories/content to provide, or you can book guests to help provide new insight,
                    stories, and value that you otherwise couldn't provide yourself to your audience. It's worth noting that having a co-host, or co-hosts, certainly can help. 
                    But, booking interesting and relevant guests is a sure way to keep your content fresh 🌱.
                    <br />
                </p>
                <p className="h3 pb-2">Booking <i>Interesting</i> Guests</p>
                <p className="text-dark lh-lg pb-3"> 
                    While at surface level, finding guests to speak on your pod may seem easy. Finding <span className="border-bottom border-dark">interesting</span> guests can be quite challenging if you if you are just starting out. And while your mom, best friend, 
                    and cousin may all be <i>willing</i> to speak on your podcast, are they relevant to your niche topic and the interests of your
                    listener audience? I'd guess probably not. So who should you book? This is an excellent question that will be different for each and every podcast, but here 
                    are a few general things to consider when looking for a guest:
                    <ol className="mt-3">
                        <li className="h6">Are they knowledgeable 🧠 or an expert 📚  in the topic you are covering?</li>
                        <p className="text-dark lh-lg">Make sure they are qualified to speak about the topic of the specific episode you are creating. Try to find guests that have industry experience or unique
                        insight into a specific topic which you want to cover.</p>
                        <li className="h6">Are they good at speaking 🗣️ ?</li>
                        <p className="text-dark lh-lg">You could land the world's top astrophysicist to speak on your science podcast, but if they aren't confortable speaking, monotone, or come across
                        as awkward, your audience will be able to tell. Find a good talker.</p>
                        <li className="h6">Are they reachable / approachable ☎️ ?</li>
                        <p className="text-dark lh-lg">If you run a relatively small podcast, you likely won't land Jeff Bezos or Barack Obama to speak on your podcast. Focus your attention on targeting guests that
                        are within reach and, if possible, connected to someone in your network of friends, family, or online connections.</p>
                        <li className="h6">Do they have the time⌛  to record?</li>
                        <p className="text-dark lh-lg">This may seem silly, but lots of people will like the idea of being a podcast guest but will continually put off recording because of their busy schedule 
                         (or even cut long interviews short). Make sure to be explicit about the approximate length of your podcast episode and the date by which you need to record when reaching out to potential guests.</p>
                    </ol>
                </p>
                <p className="h3 pb-2">Where to Look for Guests?</p>
                <p className="h5 pb-2 pt-2"> Podcast Guest Directories & Websites</p>
                <p className="text-dark lh-lg">
                    Thank God for directories and online tools... am I right?! When it comes to finding guests which are qualified to speak on certain fields and topics, podcast guest directories and tools such  
                    as <a href="https://www.guestio.com">Guestio</a>, <a href="https://www.podmatch.com">Podmatch</a>, and <a href="https://www.podcastguests.com">PodcastGuests.com</a> are great options to find guests
                    that check all the boxes (kind of like Curtain for finding sponsors and affiliates 😉). Some of these sites have thousands of people with domain specific experience who have both interest and experience being
                    a podcast guest. Be aware though that while these tools many of these tools allow you to filter, find, and schedule guests, there are trade-offs associated with each
                    of them. <br />
                    <a className="h6 ms-3 mt-2" href="https://www.guestio.com">Guestio</a><br/>
                    <p className="ms-5">An all-in-one guest booking platform where you can find, message, schedule, and pay guests. While the platform makes it extemely easy to reach out to potential guests, guests can charge a fee which you will 
                    have to pay them if they agree to be on your podcast. Another consideration is that non-pro users can only book a max of five people per month
                    through the site. The pro version will run you $97/mo as of the time of this post.</p>
                    <a className="h6 ms-3 mt-2" href="https://www.podmatch.com">Podmatch</a><br/>
                    <p className="ms-5">Podmatch is similar tool which uses AI to curate guest suggestions based on compatibility and relevance. After matching you can message, schedule, and even review guests.
                    There is a small cost, though. The "Standard" subscription starts at $6/mo, but right now they are offering a $27 incentive 💰 when you book a guest through their platform.</p>
                    <a className="h6 ms-3 mt-2" href="https://www.podcastguests.com">Podcast Guests</a><br/>
                    <p className="ms-5">This is by far the cheapest option (FREE), but it does not include the integrations and tools such as scheduling and in-app messaging like the other two tools. You can filter guests by category and use email/social media links, or in some cases forms,
                    to get in contact with prospective guest speakers. The downside is that you still often need to draft and send cold emails / social media DMs.</p>
                </p>
                <p className="h5 pb-2 pt-2">Social Media & Cold Email</p>
                <p className="text-dark lh-lg">
                    Finding guests through social media platforms is a common way to find and reach out to interesting guest speakers for your podcast. Twitter, LinkedIn, Instagram, and Facebook are all great platforms to connect with interesting people with specific areas of expertise or
                    unique experiences. The "cold DM" approach as well as cold emailing <i>may</i> yield lack-luster conversion rates, so be prepared to have to reach out to lots of people to get any great results, especially if you have a small acount or following. When reaching out to
                    people through email or DMs, make sure to stay brief and highlight the value they can get through being on your podcast (i.e. the ability to promote themselves or their product to a niche audience of interested people). You will see the most effective results when
                    you can link to a website for your pod, have a decent social media following, and are active in the podcast community or community specific to their domain of knowledge. Having a mutual connection or online friend with the person you are pitching can also help increase your chances of a response.
                </p>
                <p className="h5 pb-2 pt-2">Online Communities</p>
                <p className="text-dark lh-lg">
                    Similar to the social media approach, you can leverage interest-specific and podcast-specific online communities and forums to find guests. Facebook Groups, Subreddits, and Discord servers are all great places to get to interact with interesting people and network with potential guests.
                    There are a variety of podcast-creator focused Discord servers including <a href="https://discord.gg/XyPupUu9">Pod Squad</a>, <a href="https://discord.gg/FcFy4Qfr">Best Podcast Friends!</a>, 
                    and <a href="https://discord.gg/4YtNJ3pF">The Podcasters Community</a> (among many others) that have channels specific to finding and booking guests. Networking and engaging with other podcasters in these communities can help you get referrals to guests that were on other peoples shows, 
                    which is a great organic way to find podcast guests! On Reddit checkout Subreddits such as <a href="https://www.reddit.com/r/PodcastGuestExchange/">r/PodcastGuestExchange</a> which is a classified-style Subreddit with over 10,000 members where podcasters can post specific profile of guests they are looking for and vice versa for guests. 
                    Another example of a podcast specific community is <a href="https://www.reddit.com/r/podcasting/">r/podcasting</a>. Be sure to check some of these places out and engage with the community.
                </p>
                <p className="h5 pb-2 pt-2">Your Network & Networking</p>
                <p className="text-dark lh-lg">
                    While it may be unwise to book your great aunt Sally to talk on your podcast, it is certainly helpful to use your personal network of friends, family, and colleagues to your advantage when booking your first guests. If, for example, you are starting a podcast about dogs and a friend of yours is familiar with a veternarian or dog trainer,
                    ask them for their contact information or an introduction. If your friend themself if a vet or dog trainer... even better! As previously mentioned, getting introduced via a mutual friend or colleague gives you much better odds of getting in contact and getting a guest booked. And, while this may seem self-explanatory, 
                    you <b>don't know until you ask</b>. Ask people in your network if they know of anyone with certain backgrounds or areas of extertise. You may be surprised how large your pool of friends-of-friends really is. 
                    <br /><br />
                    Lastly, you can network with people through virtual or in-person meetups and networking events. Going to conferences, expos, local coffee meetups, virtual hangouts, and local (or online) events are all great ways to meet and book people in a less "spam-y" manner than cold email or messaging. There are a variety of <a href="https://www.buzzsprout.com/blog/podcast-conferences">podcast-specific conferences</a> that 
                    take place all over the world which are not only good ways to network with other podcasters and learn new skills, but also find other podcasters with special interests that will want to guest on your podcast. You can use websites such as <a href="https://www.meetups.com">meetups.com</a> and <a href="https://www.eventbrite.com">eventbrite.com</a> to 
                    find groups, meetups, and events that guests you are looking for may hangout at!
                </p>

                <p className="pt-4 border-top border-grey">
                    We hope this post was helpful! Be sure to stay tuned by <a href="/#signup">signing up</a> for more upcoming blog posts on topics such as <i>A Guide to Creating your Media Kit</i>, <i>Creative Ways to Marketing Your Podcast</i>, and <i>
                    Running Ads on Your Podcast: Do's and Dont's</i>. And, of course, tell your friends about us!
                </p>
            </div>
            <div className="col-2"></div>
            </div>
        </div>
        <Footer />
        </>
    )
}