import React, { useState } from 'react';
import '../../src/styles/main.css';
import BusinessSignup  from './SignUpForms/BusinessSignup';
import ApplicantSignup from './SignUpForms/ApplicantSignup';

const SignUp = () => {
    const [isBusiness, setIsBusiness] = useState(false);

    return (
        <>
            <div className="row min-vh-95 justify-content-center align-items-center text-center" id="signup" style={{backgroundColor: "#4b9cd3"}}>
                <div className="row mx-2">
                    <div className="col-3"></div>
                    <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12 justify-content-center align-items-center pt-5" align="center">
                        <p className="display-4 justify-content-center text-dark pt-3 ari"> Join the Waitlist </p>
                        <p className="h5 lead justify-content-center text-dark">
                            Sign up below to get access to the beta platform when it drops! We will also send you a <b>free</b> list of  
                            affiliate marketing programs for podcasters and an invite to our private podcast creator Discord community!
                        </p>
                    </div>
                    <div className="col-3"></div>
                </div>
                <div className="row mx-2 mt-4">
                    <div className="col-md-3 col-3"></div>
                    <div className="justify-content-center align-items-center col-sm-12 col-md-6 col-lg-6 col-xl-6 px-5">
                        <div className="card text-center bg-transparent mb-5 border-0">
                            <div className="card-header">
                            {
                                isBusiness ? 
                                <>
                                    <input type="radio" className="btn-check" name="options" id="applicant" autoComplete="off"/>
                                    <label className="btn btn-dark mx-1" for="option2" onClick={() => setIsBusiness(true)}>Advertisers</label>
                                    <input type="radio" className="btn-check " name="options" id="business" autoComplete="off"/>
                                    <label className="btn mx-1" for="option1" onClick={() => setIsBusiness(false)}>Podcasters</label>
                                </>
                                :
                                <>
                                    <input type="radio" className="btn-check" name="options" id="applicant" autoComplete="off"/>
                                    <label className="btn mx-1" for="option2" onClick={() => setIsBusiness(true)}>Advertisers</label>
                                    <input type="radio" className="btn-check " name="options" id="business" autoComplete="off"/>
                                    <label className="btn btn-dark mx-1" for="option1" onClick={() => setIsBusiness(false)}>Podcasters</label>
                                </>

                            }                                
                            </div>

                            <div className="card-body">
                                { isBusiness ?
                                    <BusinessSignup />
                                    :
                                    <ApplicantSignup />
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-3"></div>
                </div>
            </div>
    </>
    )
}

export default SignUp;