import React from "react";
import Money from "../../images/money.png";
import { Link } from "react-router-dom";
import { ArrowLeftIcon } from "@primer/octicons-react";
import Footer from '../Footer';

export function MakeMoneyPodcasting() {
    return (
        <>
        <div className="fadein" style={{background: "#F6F6F2"}}>
            <div className="row mt-5 pt-5 text-center">
                <div className="col-1">
                    <Link to="/blog" style={{texDecoration: 'none !important', color: "grey"}} className="px-5"><ArrowLeftIcon size={35} className="" /></Link>
                </div>
                <div className="col-10">
                    <p className="display-5 text-dark">Top 4 Ways to Make Money Podcasting</p>
                </div>
                <div className="col-1"></div>
            </div>
            <div className="row pt-1">
                <div className="col-md-2"></div>
                <div className="col-md-8 d-flex align-items-end justify-content-end" style={{backgroundImage: `url(${Money})`, backgroundPosition: 'center', backgroundSize: 'cover', height:'60vh', backgroundRepeat: 'no-repeat'}}>
                <p className="px-2 text-dark"> Justin Walker</p>
                <p className="px-2 text-dark"> August 18th, 2022</p>
                </div>
                <div className="col-md-2"></div>
            </div>
            <div className="row" style={{background: "#F6F6F2"}}>
            <div className="col-2"></div>
            <div className="col-8 pt-4">
                <p className="h3 pb-2">Can I start monetizing?</p>
                <p className="text-dark lh-lg"> 
                    So you started your podcast, recorded and released your first few episodes, and began to build your audience... Congrats! It's no easy feat! 
                    Now it's time for the hours you spent so far creating, editing, and growing your podcast to start to pay off. It's time to start making some money 🤑. But how? We'll explain
                    the four most common strategies below.
                    <br /><br />
                    Before we dive into monetization strategies, you may be thinking, <i>"I'm not ready to monetize yet. My podcast is still so small."</i> Here at Curtain, we disagree. Podcasts
                    of <i>all</i> sizes have the potential to monetize (even from the start 🤫)! 
                    The key is having a strategy and understanding <i>who</i> your specific audience is and <i>what</i> they like. 
                    In the case of sponsorships, finding the right size sponsors and brands that target your specific audience will allow you to generate revenue even with the smallest
                    of listener audiences &mdash; it's all in the right pitch.
                </p>
                <p className="h3 pb-2">Strategy 1: Sponsorships (Promotions)</p>
                <p className="text-dark lh-lg">
                    <p className="h5 pb-2">Value-Based Model</p>
                    When most non-podcasters think of sponsors, they probably have the concept of an organzation giving a lump sum of money to include their image or product/service information in return for some sort of brand exposure. 
                    Personally, I think of the small business names on the back of Little League jerseys. In podcasting, these "lump sum" sponsorships are what are known as the 
                    <b> value-based model</b> of sponsorships. Value-based sponsors will pay an agreed upon amount for money for each episode of your podcast that you release. If all, or none, of your listeners go and buy their
                    product or service, it doesn't affect your earnings as per-episode rate is fixed. This model can be great for newer, smaller podcasts that want to demo their podcast's value to a sponsor before going the CPM approach.
                    The sponsorship model, however, is not very common.
                    <br /><br />
                    <p className="h5 pb-2">CPM Model</p>
                    A more common sponsorship model is the <b>CPM (or cost-per-mille) model</b>. With the CPM model, a sponsor will pay you an established amount, or <i>CPM rate</i>, for every 1,000 downloads an 
                    episode of your podcast gets. In effect, you are paid based on the reach of your podcast. The typical CPM rate tends to fall in the $20-$25 range, but can vary depending on the podcast and the audience. 
                    So, if 5,000 people download your podcast at a CPM rate of $20, you will make $100. This model can be very effective for podcasts with many subscribers, since they accrue many downloads per episode. However, it
                    can also work well for some small podcasts since smaller podcasts with very niche audiences can charge higher CPM rates to advertisers that want to reach their specific audience. This, in effect, can give smaller
                    podcasts similar profit potential to much larger, less niche podcasts. Stay tuned for future blogs on this topic. 
                </p>
                <p className="h3 pb-2">Strategy 2: Affiliate Marketing (Referrals)</p>
                <p className="text-dark lh-lg">
                    Chances are, you've at least heard of <b>affiliate marketing</b>. Since many of you are likely already familiar with what it is, we won't cover it in great detail. In short, podcastcasters are paid based on their 
                    effectiveness to sell someone else's product or service. This is typically achieved, and tracked, through the use of special discount codes and links to refer people to a site. When a person uses the unique URL or code to buy something through
                    a website, the podcaster gets a cut of the sale (a commission) or a per-sale fee. There are many affiliate programs out there, many of which are open to anybody to sign up for. Commission rates can vary, but typically fall in the range
                    of 20-30%, often with additional incentives. If you are planning to incorporate affiliate programs into your monetization stategy, be sure to carefully choose a brand that your audience will love, otherwise you are unlikely to see any
                    meaningful results. 
                </p>
                <p className="h3 pb-2">Strategy 3: Premium Content and Access </p>
                <p className="text-dark lh-lg">
                    Due to the ubiquity of <a href="https://www.patreon.com/">Patreon</a>, we might as well just call this the Patreon strategy. With this strategy, podcast creators offer additional exclusive content (and often access to chatrooms) to their
                    listeners for a monthly subscription fee. Exclusive content can be additional podcast episodes, videos, or even supplemental reading materials to freely available episodes. Premium access can often include access to community chatrooms with the hosts 
                    (Slack, Discord, etc) or even private video or in person hangouts with the podcast community. Typicaly subscriptions can fall in the $5-$10/mo range. This is a great supplemental revenue source to the two options strategies listed above. Smaller podcasts,
                    however, may struggle to generate enough revenue through Patreon subscribers alone due to the small size of their audience.
                </p>
                <p className="h3 pb-2">Strategy 4: Promote Your Own Stuff </p>
                <p className="text-dark lh-lg">
                    While the final strategy won't apply to all podcasters, it is becoming increasingly common for many podcast hosts have multiple "hustles". This one is fairly self-explanatory; if you have a business that you run, you can promote your product/service
                    and website to your audience. It's a great way to add to your company's marketing strategy assuming your product/service is relevant to the interests of your audience. There is, however, opportunity cost to consider by opting to promote your own business
                    versus being paid to promote someone else's. 
                </p>
                <p className="pt-2">
                    Be sure to stay tuned by <a href="/#signup">signing up</a> for more blog posts on topics such as <i>A guide to creating your media kit</i>, <i>How to find and book great guests for your podcasts</i>, and <i>
                    Running ads on your podcast: Do's and Dont's</i>.
                </p>
            </div>
            <div className="col-2"></div>
            </div>
        </div>
        <Footer />
        </>
    )
}