import React from "react";
import Footer from "../components/Footer";
const Terms = () => {
  return (
    <>
    <div className=" mt-5 pt-5 px-3 border-bottom fadein">
      <p className="h3 pt-3">CURTAIN TERMS AND CONDITIONS</p>
      <small>Last updated September 21st, 2022</small>
      <pre className="pt-5">
        AGREEMENT TO TERMS These Terms and Conditions constitute a legally
        binding agreement made between you, whether personally or on behalf of
        an entity (“you”) and Curtain (“we,” “us” or “our”), concerning your
        access to and use of the www.joincurtain.com website as well as any
        other media form, media channel, mobile website or mobile application
        related, linked, or otherwise connected thereto (collectively, the
        “Site”).
        <br></br>You agree that by accessing the Site, you have read,
        understood, and agree to be bound by all of these Terms of Use, which
        were created using Termly's Terms and Conditions Generator. <br />
        If you do not agree with all of these Terms of Use, then you are
        expressly prohibited from using the Site and you must discontinue use
        immediately. Supplemental terms and conditions or documents that may be
        posted on the Site from time to time are hereby expressly incorporated
        herein by reference. <br />
        We reserve the right, in our sole discretion, to make changes or
        modifications to these Terms and Conditions at any time and for any
        reason. <br />
        We will alert you about any changes by updating the “Last updated” date
        of these Terms and Conditions, and you waive any right to receive
        specific notice of each such change.
        <br></br>
        <br></br>It is your responsibility to periodically review these Terms
        and Conditions to stay informed of updates. <br />
        You will be subject to, and will be deemed to have been made aware of
        and to have accepted, the changes in any revised Terms and Conditions by
        your continued use of the Site after the date such revised Terms and
        Conditions are posted.
        <br></br>The information provided on the Site is not intended for
        distribution to or use by any person or entity in any jurisdiction or
        country where such distribution or use would be contrary to law or
        regulation or which would subject us to any registration requirement
        within such jurisdiction or country.
        <br></br>Accordingly, those persons who choose to access the Site from
        other locations do so on their own initiative and are solely responsible
        for compliance with local laws, if and to the extent local laws are
        applicable.
        <br></br>These terms and conditions were created by Termly’s Terms and
        Conditions Generator.
        <br></br>The Site is intended for all audiences and users age 13+.{" "}
        <br />
        All users who are minors in the jurisdiction in which they reside
        (generally under the age of 18) must have the permission of, and be
        directly supervised by, their parent or guardian to use the Site. <br />
        If you are a minor, you must have your parent or guardian read and agree
        to these Terms and Conditions prior to you using the Site.
        <br></br>
        <br></br>INTELLECTUAL PROPERTY RIGHTS
        <br></br>
        <br></br>Unless otherwise indicated, the Site is our proprietary
        property and all source code, databases, functionality, software,
        website designs, audio, video, text, photographs, and graphics on the
        Site (collectively, the “Content”) and the trademarks, service marks,
        and logos contained therein (the “Marks”) are owned or controlled by us
        or licensed to us, and are protected by copyright and trademark laws and
        various other intellectual property rights and unfair competition laws
        of the United States, foreign jurisdictions, and international
        conventions.
        <br></br>The Content and the Marks are provided on the Site “AS IS” for
        your information and personal use only. <br />
        Except as expressly provided in these Terms and Conditions, no part of
        the Site and no Content or Marks may be copied, reproduced, aggregated,
        republished, uploaded, posted, publicly displayed, encoded, translated,
        transmitted, distributed, sold, licensed, or otherwise exploited for any
        commercial purpose whatsoever, without our express prior written
        permission.
        <br></br>Provided that you are eligible to use the Site, you are granted
        a limited license to access and use the Site and to download or print a
        copy of any portion of the Content to which you have properly gained
        access solely for your personal, non-commercial use. <br />
        We reserve all rights not expressly granted to you in and to the Site,
        the Content and the Marks.
        <br></br>
        <br></br>USER REPRESENTATIONS
        <br></br>
        <br></br>By using the Site, you represent and warrant that:
        <br></br>[(1) all registration information you submit will be true,
        accurate, current, and complete; (2) you will maintain the accuracy of
        such information and promptly update such registration information as
        necessary;]
        <br></br>(3) you have the legal capacity and you agree to comply with
        these Terms and Conditions;
        <br></br>[(4) you are not under the age of 13;]
        <br></br>(5) not a minor in the jurisdiction in which you reside[, or if
        a minor, you have received parental permission to use the Site];
        <br></br>(6) you will not access the Site through automated or non-human
        means, whether through a bot, script, or otherwise;
        <br></br>(7) you will not use the Site for any illegal or unauthorized
        purpose;
        <br></br>(8) your use of the Site will not violate any applicable law or
        regulation.
        <br></br>If you provide any information that is untrue, inaccurate, not
        current, or incomplete, we have the right to suspend or terminate your
        account and refuse any and all current or future use of the Site (or any
        portion thereof).
        <br></br>
        <br></br>USER REGISTRATION
        <br></br>
        <br></br>You may be required to register with the Site. <br />
        You agree to keep your password confidential and will be responsible for
        all use of your account and password. <br />
        We reserve the right to remove, reclaim, or change a username you select
        if we determine, in our sole discretion, that such username is
        inappropriate, obscene, or otherwise objectionable.
        <br></br>
        <br></br>PROHIBITED ACTIVITIES
        <br></br>
        <br></br>You may not access or use the Site for any purpose other than
        that for which we make the Site available. <br />
        The Site may not be used in connection with any commercial endeavors
        except those that are specifically endorsed or approved by us.
        <br></br>As a user of the Site, you agree not to:
        <br></br>1. <br />
        systematically retrieve data or other content from the Site to create or
        compile, directly or indirectly, a collection, compilation, database, or
        directory without written permission from us. <br />
        2. <br />
        make any unauthorized use of the Site, including collecting usernames
        and/or email addresses of users by electronic or other means for the
        purpose of sending unsolicited email, or creating user accounts by
        automated means or under false pretenses. <br />
        3. <br />
        use a buying agent or purchasing agent to make purchases on the Site.{" "}
        <br />
        4. <br />
        use the Site to advertise or offer to sell goods and services. <br />
        5. <br />
        circumvent, disable, or otherwise interfere with security-related
        features of the Site, including features that prevent or restrict the
        use or copying of any Content or enforce limitations on the use of the
        Site and/or the Content contained therein. 6. <br />
        engage in unauthorized framing of or linking to the Site. <br />
        7. <br />
        trick, defraud, or mislead us and other users, especially in any attempt
        to learn sensitive account information such as user passwords; 8. <br />
        make improper use of our support services or submit false reports of
        abuse or misconduct. <br />
        9. <br />
        engage in any automated use of the system, such as using scripts to send
        comments or messages, or using any data mining, robots, or similar data
        gathering and extraction tools. <br />
        10. <br />
        interfere with, disrupt, or create an undue burden on the Site or the
        networks or services connected to the Site. <br />
        11. <br />
        attempt to impersonate another user or person or use the username of
        another user. <br />
        12. <br />
        sell or otherwise transfer your profile. <br />
        13. <br />
        use any information obtained from the Site in order to harass, abuse, or
        harm another person. <br />
        14. <br />
        use the Site as part of any effort to compete with us or otherwise use
        the Site and/or the Content for any revenue-generating endeavor or
        commercial enterprise. 15. <br />
        decipher, decompile, disassemble, or reverse engineer any of the
        software comprising or in any way making up a part of the Site. <br />
        16. attempt to bypass any measures of the Site designed to prevent or
        restrict access to the Site, or any portion of the Site. <br />
        17. <br />
        harass, annoy, intimidate, or threaten any of our employees or agents
        engaged in providing any portion of the Site to you. <br />
        18. <br />
        delete the copyright or other proprietary rights notice from any
        Content. <br />
        19. <br />
        copy or adapt the Site’s software, including but not limited to Flash,
        PHP, HTML, JavaScript, or other code. <br />
        20. <br />
        upload or transmit (or attempt to upload or to transmit) viruses, Trojan
        horses, or other material, including excessive use of capital letters
        and spamming (continuous posting of repetitive text), that interferes
        with any party’s uninterrupted use and enjoyment of the Site or
        modifies, impairs, disrupts, alters, or interferes with the use,
        features, functions, operation, or maintenance of the Site. <br />
        21. <br />
        upload or transmit (or attempt to upload or to transmit) any material
        that acts as a passive or active information collection or transmission
        mechanism, including without limitation, clear graphics interchange
        formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar
        devices (sometimes referred to as “spyware” or “passive collection
        mechanisms” or “pcms”). <br />
        22. <br />
        except as may be the result of standard search engine or Internet
        browser usage, use, launch, develop, or distribute any automated system,
        including without limitation, any spider, robot, cheat utility, scraper,
        or offline reader that accesses the Site, or using or launching any
        unauthorized script or other software. <br />
        23. <br />
        disparage, tarnish, or otherwise harm, in our opinion, us and/or the
        Site. <br />
        24. <br />
        use the Site in a manner inconsistent with any applicable laws or
        regulations.
        <br></br>
        <br></br>USER GENERATED CONTRIBUTIONS
        <br></br>
        <br></br>The Site may invite you to chat, contribute to, or participate
        in blogs, message boards, online forums, and other functionality, and
        may provide you with the opportunity to create, submit, post, display,
        transmit, perform, publish, distribute, or broadcast content and
        materials to us or on the Site, including but not limited to text,
        writings, video, audio, photographs, graphics, comments, suggestions, or
        personal information or other material (collectively, "Contributions").
        <br></br>Contributions may be viewable by other users of the Site and
        through third-party websites. <br />
        As such, any Contributions you transmit may be treated as
        non-confidential and non-proprietary. <br />
        When you create or make available any Contributions, you thereby
        represent and warrant that:
        <br></br>1. <br />
        the creation, distribution, transmission, public display, or
        performance, and the accessing, downloading, or copying of your
        Contributions do not and will not infringe the proprietary rights,
        including but not limited to the copyright, patent, trademark, trade
        secret, or moral rights of any third party. <br />
        2. <br />
        you are the creator and owner of or have the necessary licenses, rights,
        consents, releases, and permissions to use and to authorize us, the
        Site, and other users of the Site to use your Contributions in any
        manner contemplated by the Site and these Terms and Conditions. <br />
        3. <br />
        you have the written consent, release, and/or permission of each and
        every identifiable individual person in your Contributions to use the
        name or likeness of each and every such identifiable individual person
        to enable inclusion and use of your Contributions in any manner
        contemplated by the Site and these Terms and Conditions. <br />
        4. <br />
        your Contributions are not false, inaccurate, or misleading. <br />
        5. <br />
        your Contributions are not unsolicited or unauthorized advertising,
        promotional materials, pyramid schemes, chain letters, spam, mass
        mailings, or other forms of solicitation. <br />
        6. <br />
        your Contributions are not obscene, lewd, lascivious, filthy, violent,
        harassing, libelous, slanderous, or otherwise objectionable (as
        determined by us). <br />
        7. <br />
        your Contributions do not ridicule, mock, disparage, intimidate, or
        abuse anyone. <br />
        8. <br />
        your Contributions do not advocate the violent overthrow of any
        government or incite, encourage, or threaten physical harm against
        another. <br />
        9. <br />
        your Contributions do not violate any applicable law, regulation, or
        rule. <br />
        10. <br />
        your Contributions do not violate the privacy or publicity rights of any
        third party. <br />
        11. your Contributions do not contain any material that solicits
        personal information from anyone under the age of 18 or exploits people
        under the age of 18 in a sexual or violent manner. <br />
        12. <br />
        your Contributions do not violate any federal or state law concerning
        child pornography, or otherwise intended to protect the health or
        well-being of minors; 13. your Contributions do not include any
        offensive comments that are connected to race, national origin, gender,
        sexual preference, or physical handicap. <br />
        14. <br />
        your Contributions do not otherwise violate, or link to material that
        violates, any provision of these Terms and Conditions, or any applicable
        law or regulation. <br />
        Any use of the Site in violation of the foregoing violates these Terms
        and Conditions and may result in, among other things, termination or
        suspension of your rights to use the Site.
        <br></br>CONTRIBUTION LICENSE
        <br></br>
        By posting your Contributions to any part of the Site [or making
        Contributions accessible to the Site by linking your account from the
        Site to any of your social networking accounts], you automatically
        grant, and you represent and warrant that you have the right to grant,
        to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive,
        transferable, royalty-free, fully-paid, worldwide right, and license to
        host, use, copy, reproduce, disclose, sell, resell, publish, broadcast,
        retitle, archive, store, cache, publicly perform, publicly display,
        reformat, translate, transmit, excerpt (in whole or in part), and
        distribute such Contributions (including, without limitation, your image
        and voice) for any purpose, commercial, advertising, or otherwise, and
        to prepare derivative works of, or incorporate into other works, such
        Contributions, and grant and authorize sublicenses of the foregoing.{" "}
        <br />
        The use and distribution may occur in any media formats and through any
        media channels.
        <br></br>
        This license will apply to any form, media, or technology now known or
        hereafter developed, and includes our use of your name, company name,
        and franchise name, as applicable, and any of the trademarks, service
        marks, trade names, logos, and personal and commercial images you
        provide. <br />
        You waive all moral rights in your Contributions, and you warrant that
        moral rights have not otherwise been asserted in your Contributions.
        <br></br>
        We do not assert any ownership over your Contributions. <br />
        You retain full ownership of all of your Contributions and any
        intellectual property rights or other proprietary rights associated with
        your Contributions. We are not liable for any statements or
        representations in your Contributions provided by you in any area on the
        Site.
        <br></br>
        You are solely responsible for your Contributions to the Site and you
        expressly agree to exonerate us from any and all responsibility and to
        refrain from any legal action against us regarding your Contributions.
        <br></br>
        We have the right, in our sole and absolute discretion, (1) to edit,
        redact, or otherwise change any Contributions; (2) to re-categorize any
        Contributions to place them in more appropriate locations on the Site;
        and (3) to pre-screen or delete any Contributions at any time and for
        any reason, without notice. <br />
        We have no obligation to monitor your Contributions.
        <br></br>
        <br></br>GUIDELINES FOR REVIEWS
        <br></br>
        <br></br>We may provide you areas on the Site to leave reviews or
        ratings. <br />
        When posting a review, you must comply with the following criteria:
        <br></br>
        (1) you should have firsthand experience with the person/entity being
        reviewed;
        <br></br>
        (2) your reviews should not contain offensive profanity, or abusive,
        racist, offensive, or hate language;
        <br></br>
        (3) your reviews should not contain discriminatory references based on
        religion, race, gender, national origin, age, marital status, sexual
        orientation, or disability;
        <br></br>
        (4) your reviews should not contain references to illegal activity;
        <br></br>
        (5) you should not be affiliated with competitors if posting negative
        reviews;
        <br></br>
        (6) you should not make any conclusions as to the legality of conduct;
        <br></br>
        (7) you may not post any false or misleading statements;
        <br></br>
        (8) you may not organize a campaign encouraging others to post reviews,
        whether positive or negative.
        <br></br>
        We may accept, reject, or remove reviews in our sole discretion. <br />
        We have absolutely no obligation to screen reviews or to delete reviews,
        even if anyone considers reviews objectionable or inaccurate. <br />
        Reviews are not endorsed by us, and do not necessarily represent our
        opinions or the views of any of our affiliates or partners.
        <br></br>
        We do not assume liability for any review or for any claims,
        liabilities, or losses resulting from any review. <br />
        By posting a review, you hereby grant to us a perpetual, non-exclusive,
        worldwide, royalty-free, fully-paid, assignable, and sublicensable right
        and license to reproduce, modify, translate, transmit by any means,
        display, perform, and/or distribute all content relating to reviews.
        <br></br>
        <br></br>SOCIAL MEDIA
        <br></br>
        <br></br>As part of the functionality of the Site, you may link your
        account with online accounts you have with third-party service providers
        (each such account, a “Third-Party Account”) by either: (1) providing
        your Third-Party Account login information through the Site; or (2)
        allowing us to access your Third-Party Account, as is permitted under
        the applicable terms and conditions that govern your use of each
        Third-Party Account.
        <br></br>
        You represent and warrant that you are entitled to disclose your
        Third-Party Account login information to us and/or grant us access to
        your Third-Party Account, without breach by you of any of the terms and
        conditions that govern your use of the applicable Third-Party Account,
        and without obligating us to pay any fees or making us subject to any
        usage limitations imposed by the third-party service provider of the
        Third-Party Account.
        <br></br>
        By granting us access to any Third-Party Accounts, you understand that
        (1) we may access, make available, and store (if applicable) any content
        that you have provided to and stored in your Third-Party Account (the
        “Social Network Content”) so that it is available on and through the
        Site via your account, including without limitation any friend lists and
        (2) we may submit to and receive from your Third-Party Account
        additional information to the extent you are notified when you link your
        account with the Third-Party Account.
        <br></br>
        Depending on the Third-Party Accounts you choose and subject to the
        privacy settings that you have set in such Third-Party Accounts,
        personally identifiable information that you post to your Third-Party
        Accounts may be available on and through your account on the Site.
        <br></br>
        Please note that if a Third-Party Account or associated service becomes
        unavailable or our access to such Third-Party Account is terminated by
        the third-party service provider, then Social Network Content may no
        longer be available on and through the Site. <br />
        You will have the ability to disable the connection between your account
        on the Site and your Third-Party Accounts at any time.
        <br></br>
        PLEASE NOTE THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE
        PROVIDERS ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS GOVERNED SOLELY
        BY YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDERS. <br />
        We make no effort to review any Social Network Content for any purpose,
        including but not limited to, for accuracy, legality, or
        non-infringement, and we are not responsible for any Social Network
        Content.
        <br></br>
        You acknowledge and agree that we may access your email address book
        associated with a Third-Party Account and your contacts list stored on
        your mobile device or tablet computer solely for purposes of identifying
        and informing you of those contacts who have also registered to use the
        Site.
        <br></br>
        You can deactivate the connection between the Site and your Third-Party
        Account by contacting us using the contact information below or through
        your account settings (if applicable). <br />
        We will attempt to delete any information stored on our servers that was
        obtained through such Third-Party Account, except the username and
        profile picture that become associated with your account.
        <br></br>
        <br></br>SUBMISSIONS
        <br></br>
        <br></br>You acknowledge and agree that any questions, comments,
        suggestions, ideas, feedback, or other information regarding the Site
        ("Submissions") provided by you to us are non-confidential and shall
        become our sole property. <br />
        We shall own exclusive rights, including all intellectual property
        rights, and shall be entitled to the unrestricted use and dissemination
        of these Submissions for any lawful purpose, commercial or otherwise,
        without acknowledgment or compensation to you.
        <br></br>
        You hereby waive all moral rights to any such Submissions, and you
        hereby warrant that any such Submissions are original with you or that
        you have the right to submit such Submissions. <br />
        You agree there shall be no recourse against us for any alleged or
        actual infringement or misappropriation of any proprietary right in your
        Submissions.
        <br></br>
        <br></br>
        THIRD-PARTY WEBSITES AND CONTENT
        <br></br>
        The Site may contain (or you may be sent via the Site) links to other
        websites ("Third-Party Websites") as well as articles, photographs,
        text, graphics, pictures, designs, music, sound, video, information,
        applications, software, and other content or items belonging to or
        originating from third parties ("Third-Party Content").
        <br></br>
        Such Third-Party Websites and Third-Party Content are not investigated,
        monitored, or checked for accuracy, appropriateness, or completeness by
        us, and we are not responsible for any Third-Party Websites accessed
        through the Site or any Third-Party Content posted on, available
        through, or installed from the Site, including the content, accuracy,
        offensiveness, opinions, reliability, privacy practices, or other
        policies of or contained in the Third-Party Websites or the Third-Party
        Content.
        <br></br>
        Inclusion of, linking to, or permitting the use or installation of any
        Third-Party Websites or any Third-Party Content does not imply approval
        or endorsement thereof by us. <br />
        If you decide to leave the Site and access the Third-Party Websites or
        to use or install any Third-Party Content, you do so at your own risk,
        and you should be aware these Terms and Conditions no longer govern.
        <br></br>
        You should review the applicable terms and policies, including privacy
        and data gathering practices, of any website to which you navigate from
        the Site or relating to any applications you use or install from the
        Site. <br />
        Any purchases you make through Third-Party Websites will be through
        other websites and from other companies, and we take no responsibility
        whatsoever in relation to such purchases which are exclusively between
        you and the applicable third party.
        <br></br>
        You agree and acknowledge that we do not endorse the products or
        services offered on Third-Party Websites and you shall hold us harmless
        from any harm caused by your purchase of such products or services.
        Additionally, you shall hold us harmless from any losses sustained by
        you or harm caused to you relating to or resulting in any way from any
        Third-Party Content or any contact with Third-Party Websites.
        <br></br>
        <br></br>SITE MANAGEMENT
        <br></br>
        We reserve the right, but not the obligation, to:
        <br></br>
        (1) monitor the Site for violations of these Terms and Conditions;
        <br></br>
        (2) take appropriate legal action against anyone who, in our sole
        discretion, violates the law or these Terms and Conditions, including
        without limitation, reporting such user to law enforcement authorities;
        <br></br>
        (3) in our sole discretion and without limitation, refuse, restrict
        access to, limit the availability of, or disable (to the extent
        technologically feasible) any of your Contributions or any portion
        thereof;
        <br></br>
        (4) in our sole discretion and without limitation, notice, or liability,
        to remove from the Site or otherwise disable all files and content that
        are excessive in size or are in any way burdensome to our systems;
        <br></br>
        (5) otherwise manage the Site in a manner designed to protect our rights
        and property and to facilitate the proper functioning of the Site.
        <br></br>
        <br></br>
        PRIVACY POLICY
        <br></br>
        <br></br>By using the Site, you agree to be bound by our Privacy Policy,
        which is incorporated into these Terms and Conditions. <br />
        Please be advised the Site is hosted in the United States.
        <br></br>
        If you access the Site from the European Union, Asia, or any other
        region of the world with laws or other requirements governing personal
        data collection, use, or disclosure that differ from applicable laws in
        the United States, then through your continued use of the Site, you are
        transferring your data to the United States, and you expressly consent
        to have your data transferred to and processed in the United States.
        <br></br>
        [Further, we do not knowingly accept, request, or solicit information
        from children or knowingly market to children. <br />
        Therefore, in accordance with the U.S. <br />
        Children’s Online Privacy Protection Act, if we receive actual knowledge
        that anyone under the age of 13 has provided personal information to us
        without the requisite and verifiable parental consent, we will delete
        that information from the Site as quickly as is reasonably practical.]
        <br></br>
        <br></br>DIGITAL MILLENNIUM COPYRIGHT ACT (DMCA) NOTICE AND POLICY
        <br></br>
        <br></br>Notifications
        <br></br>
        <br></br>We respect the intellectual property rights of others. <br />
        If you believe that any material available on or through the Site
        infringes upon any copyright you own or control, please immediately
        notify our Designated Copyright Agent using the contact information
        provided below (a “Notification”).
        <br></br>A copy of your Notification will be sent to the person who
        posted or stored the material addressed in the Notification. <br />
        Please be advised that pursuant to federal law you may be held liable
        for damages if you make material misrepresentations in a Notification.{" "}
        <br />
        Thus, if you are not sure that material located on or linked to by the
        Site infringes your copyright, you should consider first contacting an
        attorney.
        <br></br>
        All Notifications should meet the requirements of DMCA 17 U.S.C. <br />§
        512(c)(3) and include the following information:
        <br></br>
        (1) A physical or electronic signature of a person authorized to act on
        behalf of the owner of an exclusive right that is allegedly infringed;
        <br></br>
        (2) identification of the copyrighted work claimed to have been
        infringed, or, if multiple copyrighted works on the Site are covered by
        the Notification, a representative list of such works on the Site;
        <br></br>
        (3) identification of the material that is claimed to be infringing or
        to be the subject of infringing activity and that is to be removed or
        access to which is to be disabled, and information reasonably sufficient
        to permit us to locate the material;
        <br></br>
        (4) information reasonably sufficient to permit us to contact the
        complaining party, such as an address, telephone number, and, if
        available, an email address at which the complaining party may be
        contacted;
        <br></br>
        (5) a statement that the complaining party has a good faith belief that
        use of the material in the manner complained of is not authorized by the
        copyright owner, its agent, or the law;
        <br></br>
        (6) a statement that the information in the notification is accurate,
        and under penalty of perjury, that the complaining party is authorized
        to act on behalf of the owner of an exclusive right that is allegedly
        infringed upon.
        <br></br>
        <br></br>Counter Notification
        <br></br>
        <br></br>If you believe your own copyrighted material has been removed
        from the Site as a result of a mistake or misidentification, you may
        submit a written counter notification to us using the contact
        information at the bottom of this page
        <br></br>
        To be an effective Counter Notification under the DMCA, your Counter
        Notification must include substantially the following:
        <br></br>
        (1) identification of the material that has been removed or disabled and
        the location at which the material appeared before it was removed or
        disabled;
        <br></br>
        (2) a statement that you consent to the jurisdiction of the Federal
        District Court in which your address is located, or if your address is
        outside the United States, for any judicial district in which we are
        located;
        <br></br>
        (3) a statement that you will accept service of process from the party
        that filed the Notification or the party's agent;
        <br></br>
        (4) your name, address, and telephone number;
        <br></br>
        (5) a statement under penalty of perjury that you have a good faith
        belief that the material in question was removed or disabled as a result
        of a mistake or misidentification of the material to be removed or
        disabled;
        <br></br>
        (6) your physical or electronic signature.
        <br></br>
        If you send us a valid, written Counter Notification meeting the
        requirements described above, we will restore your removed or disabled
        material, unless we first receive notice from the party filing the
        Notification informing us that such party has filed a court action to
        restrain you from engaging in infringing activity related to the
        material in question.
        <br></br>
        Please note that if you materially misrepresent that the disabled or
        removed content was removed by mistake or misidentification, you may be
        liable for damages, including costs and attorney's fees. <br />
        Filing a false Counter Notification constitutes perjury.
        <br></br>
        <br></br>COPYRIGHT INFRINGEMENTS
        <br></br>
        <br></br>We respect the intellectual property rights of others. <br />
        If you believe that any material available on or through the Site
        infringes upon any copyright you own or control, please immediately
        notify us using the contact information provided below (a
        “Notification”). <br />A copy of your Notification will be sent to the
        person who posted or stored the material addressed in the Notification.
        <br></br>
        Please be advised that pursuant to federal law you may be held liable
        for damages if you make material misrepresentations in a Notification.
        Thus, if you are not sure that material located on or linked to by the
        Site infringes your copyright, you should consider first contacting an
        attorney.]
        <br></br>
        <br></br>TERM AND TERMINATION
        <br></br>
        <br></br>These Terms and Conditions shall remain in full force and
        effect while you use the Site. <br />
        WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS AND CONDITIONS, WE
        RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
        LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING BLOCKING
        CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
        INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY,
        OR COVENANT CONTAINED IN THESE TERMS AND CONDITIONS OR OF ANY APPLICABLE
        LAW OR REGULATION. <br />
        WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE [YOUR
        ACCOUNT AND] ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME,
        WITHOUT WARNING, IN OUR SOLE DISCRETION.
        <br></br>
        If we terminate or suspend your account for any reason, you are
        prohibited from registering and creating a new account under your name,
        a fake or borrowed name, or the name of any third party, even if you may
        be acting on behalf of the third party.
        <br></br>
        In addition to terminating or suspending your account, we reserve the
        right to take appropriate legal action, including without limitation
        pursuing civil, criminal, and injunctive redress.
        <br></br>
        <br></br>MODIFICATIONS AND INTERRUPTIONS
        <br></br>
        <br></br>We reserve the right to change, modify, or remove the contents
        of the Site at any time or for any reason at our sole discretion without
        notice. <br />
        However, we have no obligation to update any information on our Site.{" "}
        <br />
        We also reserve the right to modify or discontinue all or part of the
        Site without notice at any time.
        <br></br>
        We will not be liable to you or any third party for any modification,
        price change, suspension, or discontinuance of the Site.
        <br></br>
        We cannot guarantee the Site will be available at all times. <br />
        We may experience hardware, software, or other problems or need to
        perform maintenance related to the Site, resulting in interruptions,
        delays, or errors.
        <br></br>
        We reserve the right to change, revise, update, suspend, discontinue, or
        otherwise modify the Site at any time or for any reason without notice
        to you. <br />
        You agree that we have no liability whatsoever for any loss, damage, or
        inconvenience caused by your inability to access or use the Site during
        any downtime or discontinuance of the Site.
        <br></br>
        Nothing in these Terms and Conditions will be construed to obligate us
        to maintain and support the Site or to supply any corrections, updates,
        or releases in connection therewith.
        <br></br>
        <br></br>GOVERNING LAW
        <br></br>
        <br></br>These Terms and Conditions and your use of the Site are
        governed by and construed in accordance with the laws of the State of DE
        applicable to agreements made and to be entirely performed within the
        State/Commonwealth of DE, without regard to its conflict of law
        principles.
        <br></br>
        <br></br>DISPUTE RESOLUTION
        <br></br>
        <br></br>Informal Negotiations
        <br></br>
        <br></br>To expedite resolution and control the cost of any dispute,
        controversy, or claim related to these Terms and Conditions (each a
        "Dispute" and collectively, the “Disputes”) brought by either you or us
        (individually, a “Party” and collectively, the “Parties”), the Parties
        agree to first attempt to negotiate any Dispute (except those Disputes
        expressly provided below) informally for at least 10 days before
        initiating arbitration. <br />
        Such informal negotiations commence upon written notice from one Party
        to the other Party.
        <br></br>
        <br></br>Binding Arbitration
        <br></br>
        <br></br>If the Parties are unable to resolve a Dispute through informal
        negotiations, the Dispute (except those Disputes expressly excluded
        below) will be finally and exclusively resolved by binding arbitration.
        YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE RIGHT TO
        SUE IN COURT AND HAVE A JURY TRIAL.
        <br></br>
        The arbitration shall be commenced and conducted under the Commercial
        Arbitration Rules of the American Arbitration Association ("AAA") and,
        where appropriate, the AAA’s Supplementary Procedures for Consumer
        Related Disputes ("AAA Consumer Rules"), both of which are available at
        the AAA website www.adr.org.
        <br></br>
        Your arbitration fees and your share of arbitrator compensation shall be
        governed by the AAA Consumer Rules and, where appropriate, limited by
        the AAA Consumer Rules. <br />
        [If such costs are determined to by the arbitrator to be excessive, we
        will pay all arbitration fees and expenses.]
        <br></br>
        The arbitration may be conducted in person, through the submission of
        documents, by phone, or online. <br />
        The arbitrator will make a decision in writing, but need not provide a
        statement of reasons unless requested by either Party.
        <br></br>
        The arbitrator must follow applicable law, and any award may be
        challenged if the arbitrator fails to do so. <br />
        Except where otherwise required by the applicable AAA rules or
        applicable law, the arbitration will take place in New Castle County,
        DE.
        <br></br>
        Except as otherwise provided herein, the Parties may litigate in court
        to compel arbitration, stay proceedings pending arbitration, or to
        confirm, modify, vacate, or enter judgment on the award entered by the
        arbitrator.
        <br></br>
        If for any reason, a Dispute proceeds in court rather than arbitration,
        the Dispute shall be commenced or prosecuted in the state and federal
        courts located in New Castle County, DE, and the Parties hereby consent
        to, and waive all defenses of lack of personal jurisdiction, and forum
        non conveniens with respect to venue and jurisdiction in such state and
        federal courts.
        <br></br>
        Application of the United Nations Convention on Contracts for the
        International Sale of Goods and the Uniform Computer Information
        Transaction Act (UCITA) are excluded from these Terms and Conditions.
        <br></br>
        In no event shall any Dispute brought by either Party related in any way
        to the Site be commenced more than one (1) year after the cause of
        action arose. <br />
        If this provision is found to be illegal or unenforceable, then neither
        Party will elect to arbitrate any Dispute falling within that portion of
        this provision found to be illegal or unenforceable and such Dispute
        shall be decided by a court of competent jurisdiction within the courts
        listed for jurisdiction above, and the Parties agree to submit to the
        personal jurisdiction of that court.
        <br></br>
        <br></br>
        Restrictions
        <br></br>
        <br></br>The Parties agree that any arbitration shall be limited to the
        Dispute between the Parties individually. <br />
        To the full extent permitted by law, (a) no arbitration shall be joined
        with any other proceeding; (b) there is no right or authority for any
        Dispute to be arbitrated on a class-action basis or to utilize class
        action procedures; and (c) there is no right or authority for any
        Dispute to be brought in a purported representative capacity on behalf
        of the general public or any other persons.
        <br></br>
        Exceptions to [Informal Negotiations and] Arbitration The Parties agree
        that the following Disputes are not subject to the above provisions
        concerning [informal negotiations and] binding arbitration: (a) any
        Disputes seeking to enforce or protect, or concerning the validity of,
        any of the intellectual property rights of a Party; (b) any Dispute
        related to, or arising from, allegations of theft, piracy, invasion of
        privacy, or unauthorized use; and (c) any claim for injunctive relief.
        <br></br>
        If this provision is found to be illegal or unenforceable, then neither
        Party will elect to arbitrate any Dispute falling within that portion of
        this provision found to be illegal or unenforceable and such Dispute
        shall be decided by a court of competent jurisdiction within the courts
        listed for jurisdiction above, and the Parties agree to submit to the
        personal jurisdiction of that court.
        <br></br>
        <br></br>CORRECTIONS
        <br></br>
        <br></br>There may be information on the Site that contains
        typographical errors, inaccuracies, or omissions that may relate to the
        Site, including descriptions, pricing, availability, and various other
        information. <br />
        We reserve the right to correct any errors, inaccuracies, or omissions
        and to change or update the information on the Site at any time, without
        prior notice.
        <br></br>
        <br></br>DISCLAIMER
        <br></br>
        <br></br>THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. <br />
        YOU AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR
        SOLE RISK. <br />
        TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
        EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND YOUR USE THEREOF,
        INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
        WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR
        COMPLETENESS OF THE SITE’S CONTENT OR THE CONTENT OF ANY WEBSITES LINKED
        TO THE SITE AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY
        (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2)
        PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING
        FROM YOUR ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO
        OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
        AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
        CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES,
        TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE
        SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY
        CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS
        A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE
        MADE AVAILABLE VIA THE SITE. <br />
        WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
        PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
        SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
        FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A PARTY
        TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN
        YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
        <br></br>
        AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN
        ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
        WHERE APPROPRIATE.
        <br></br>
        <br></br>LIMITATIONS OF LIABILITY
        <br></br>
        <br></br>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
        LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
        CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES,
        INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES
        ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES.
        <br></br>
        IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR
        LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.]
        <br></br>
        <br></br>INDEMNIFICATION
        <br></br>
        <br></br>You agree to defend, indemnify, and hold us harmless, including
        our subsidiaries, affiliates, and all of our respective officers,
        agents, partners, and employees, from and against any loss, damage,
        liability, claim, or demand, including reasonable attorneys’ fees and
        expenses, made by any third party due to or arising out of: (1) [your
        Contributions]; (2) use of the Site; (3) breach of these Terms and
        Conditions; (4) any breach of your representations and warranties set
        forth in these Terms and Conditions; (5) your violation of the rights of
        a third party, including but not limited to intellectual property
        rights; or (6) any overt harmful act toward any other user of the Site
        with whom you connected via the Site.
        <br></br>
        Notwithstanding the foregoing, we reserve the right, at your expense, to
        assume the exclusive defense and control of any matter for which you are
        required to indemnify us, and you agree to cooperate, at your expense,
        with our defense of such claims. <br />
        We will use reasonable efforts to notify you of any such claim, action,
        or proceeding which is subject to this indemnification upon becoming
        aware of it.
        <br></br>
        <br></br>USER DATA
        <br></br>
        <br></br>We will maintain certain data that you transmit to the Site for
        the purpose of managing the Site, as well as data relating to your use
        of the Site. <br />
        Although we perform regular routine backups of data, you are solely
        responsible for all data that you transmit or that relates to any
        activity you have undertaken using the Site.
        <br></br>
        You agree that we shall have no liability to you for any loss or
        corruption of any such data, and you hereby waive any right of action
        against us arising from any such loss or corruption of such data.
        <br></br>
        <br></br>ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
        <br></br>
        <br></br>Visiting the Site, sending us emails, and completing online
        forms constitute electronic communications. <br />
        You consent to receive electronic communications, and you agree that all
        agreements, notices, disclosures, and other communications we provide to
        you electronically, via email and on the Site, satisfy any legal
        requirement that such communication be in writing.
        <br></br>
        YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS,
        AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
        RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE.
        <br></br>
        You hereby waive any rights or requirements under any statutes,
        regulations, rules, ordinances, or other laws in any jurisdiction which
        require an original signature or delivery or retention of non-electronic
        records, or to payments or the granting of credits by any means other
        than electronic means.
        <br></br>
        <br></br>CALIFORNIA USERS AND RESIDENTS
        <br></br>
        <br></br>
        If any complaint with us is not satisfactorily resolved, you can contact
        the Complaint Assistance Unit of the Division of Consumer Services of
        the California Department of Consumer Affairs in writing at 1625 North
        Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone
        at (800) 952-5210 or (916) 445-1254.
        <br></br>
        <br></br>
        MISCELLANEOUS
        <br></br>
        <br></br>These Terms and Conditions and any policies or operating rules
        posted by us on the Site constitute the entire agreement and
        understanding between you and us. <br />
        Our failure to exercise or enforce any right or provision of these Terms
        and Conditions shall not operate as a waiver of such right or provision.
        <br></br>
        These Terms and Conditions operate to the fullest extent permissible by
        law. <br />
        We may assign any or all of our rights and obligations to others at any
        time. <br />
        We shall not be responsible or liable for any loss, damage, delay, or
        failure to act caused by any cause beyond our reasonable control.
        <br></br>
        If any provision or part of a provision of these Terms and Conditions is
        determined to be unlawful, void, or unenforceable, that provision or
        part of the provision is deemed severable from these Terms and
        Conditions and does not affect the validity and enforceability of any
        remaining provisions.
        <br></br>
        There is no joint venture, partnership, employment or agency
        relationship created between you and us as a result of these Terms and
        Conditions or use of the Site. <br />
        You agree that these Terms and Conditions will not be construed against
        us by virtue of having drafted them.
        <br></br>
        You hereby waive any and all defenses you may have based on the
        electronic form of these Terms and Conditions and the lack of signing by
        the parties hereto to execute these Terms and Conditions.
        <br></br>
        <br></br>CONTACT US
        <br></br>
        <br></br>In order to resolve a complaint regarding the Site or to
        receive further information regarding use of the Site, please contact us
        at:
        <br></br>
        <br></br>Justin Walker <br></br>1413 N. <br />
        Harrison Street Unit 3 <br></br>
        +1 (717) 579 - 4528<br></br>
        team@joincurtain.com
      </pre>
    </div>
    <Footer />
    </>
  );
};

export default Terms;
