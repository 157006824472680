import React from "react";
import "../../src/styles/main.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="row min-vh-50 mt-0 pt-0">
        <div className="col-3"></div>
        <div className="col-md-4 mt-2 px-3">
          <p className="h6 font-weight-light text-secondary ">
            Interested in joining the team? Email{" "}
            <a href="mailTo: team@joincurtain.com">team@joincurtain.com</a>
          </p>
          <p className="h6 font-weight-light text-secondary ">
            Send us your thoughts at{" "}
            <a href="mailTo: team@joincurtain.com">team@joincurtain.com</a>
          </p>
        </div>
        <div className="col-md-3 mt-2 px-2">
          <a href="/terms" style={{ textDecoration: "none" }}>
            Terms and Conditions
          </a><br />
          <a href="/cookies" style={{ textDecoration: "none" }}>
            Cookies Policy
          </a>
        </div>
        <div className="col-2"></div>
      </div>
      <div className="row">
        <p className="h6 font-weight-light text-secondary text-center pt-3">
            © Copyright Curtain 2022
        </p>
      </div>
    </>
  );
};

export default Footer;
