import React, { useState } from 'react';
import '../../../src/styles/main.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ApplicantSignup = () => {
    const [submitSuccessful, setSubmitSuccessful] = useState(false);
    const [selected, setSelected] = useState(false);
    const [showModal, setShowModal] = useState(false);
    
    const [data, setData] = useState({
        signupType: "Podcaster",
        email: null,
        genre: null,
        podcast: null,
        agree: null
    });

    const handleCloseModal = () => { 
        setShowModal(false);
        document.getElementById("submitForm").reset();
    }

    const DisplayModal = (e) => {
        console.log("HERE IN THE DISPLAY MODAL");
        return(
            <Modal show={showModal} animation={false}>
                { submitSuccessful ?
                    <>
                        <Modal.Header>
                            <Modal.Title className="text-success">You're in!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="text-muted">Thanks for joining the waitlist!🤗. You should receive a confirmation email in your inbox.</p>
                        </Modal.Body>
                    </>
                    :
                    <>
                        <Modal.Header>
                            <Modal.Title className="text-danger">Submission failed</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p className="text-muted">Looks like something went wrong. Sorry about that! Please email us instead at team@joincurtain.com, and we will manually add you to the list.</p>
                        </Modal.Body>
                    </>
                }
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
    
    async function handleSubmit(e) {
        e.preventDefault();
        setSelected(false);

        await fetch('/submitUser', 
            {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-Type": "application/json"
                },
                mode: "cors"
            }).then( (response) => {
                if(response.ok) {
                    setSubmitSuccessful(true);
                } else {
                    setSubmitSuccessful(false);
                }
            });
        setShowModal(true);
    }
        
    const setField = (e) => {
        setData({...data, [e.target.name] : e.target.value});
        if(e.target.name == "genre") {
            setSelected(true);
        }
    }

    return (
        <>
            {/* <p className="justify-content-center text-muted pt-3"> We <i>strongly</i> encourage private beta users to help offer feedback on the platform. <br></br>Feedback and advice are crucial for incorporating the features and functionality you want in the public release!</p> */}
            <form  className="ApplicationForm" id="submitForm" onSubmit={handleSubmit}>
                <div className="col-md pt-3 form-group">
                    <input type="email" className="form-control" id="email" name="email" placeholder="Email" onChange={(event) => setField(event)} required />
                </div>
                <div className="col-md pt-3 form-group">
                    <select className={selected ? "form-control dropdown text-dark" : "form-control dropdown text-secondary"} id="genre" name="genre" onChange={(event) => setField(event)} required>
                        <option className="placeholder" selected hidden>Podcast Genre</option>
                        <option> Agriculture </option>
                        <option> Architecture / Construction </option>
                        <option> Arts / Entertainment </option> 
                        <option> Business </option> 
                        <option> Childcare </option>
                        <option> Communications</option>
                        <option> Consulting </option>
                        <option> Culinary </option>
                        <option> Education / Academia</option>
                        <option> Engineering </option>
                        <option> Finance </option>
                        <option> General Labor</option>     
                        <option> Government </option>  
                        <option> Law </option>                 
                        <option> Medicine / Healthcare </option>
                        <option> Retail </option>
                        <option> Sales / Marketing </option>
                        <option> Science / Mathematics</option>
                        <option> Sports</option>
                        <option> Technology </option>
                        <option> Other </option>
                    </select>
                </div>                  
                <div className="col-md pt-3 form-group">
                    <input type="text" className="form-control text-dark" id="podcast" name="podcast" onChange={(event) => setField(event)} placeholder="Podcast name" required></input>
                </div>
                <div className="col-md pt-3 form-group">
                    <input className="form-check-input checkbox" type="checkbox" id="agree" name="agree" value="yes" onChange={(event) => setField(event)} required/>
                    <label className="form-check-label px-2 text-dark" for="gridCheck">
                        I agree to receive updates (we promise not to spam your inbox 😃)
                    </label>
                </div>
                <button type="submit" className="btn signup-btn mt-3 btn-dark">Sign up</button>
            </form>
            { showModal && <DisplayModal/> }
        </>
    )
}

export default ApplicantSignup;