import React from "react";
import '../../src/styles/main.css';
import { DotFillIcon } from "@primer/octicons-react";
const CoreFeatures = () => {

        return (
            <>     
        <div className="row who-light pt-5 pb-5 " id="who">
            <div className="col col-lg-4 align-self-middle d-flex align-items-center">
                <p className="display-4 justify-content-center text-dark text-justify-right d-block mx-auto ari"> Why <br></br> Use <br></br>Curtain? </p>
            </div>
            <div className="col-lg-3 align-self-center">
                    <p className="h2 ari black pt-3 px-2"> Better Partnerships </p>
                    <p className="h5 ari black px-2"> Find and connect with brands and sponsors that your listener audience will truly love using our filters. </p>
                    <p className="h2 ari black pt-4 px-2"> Small-Pod Friendly </p>
                    <p className="h5 ari black px-2"> Unlike podcast ad networks, our platform is open to podcasts of all sizes, making it possible for podcast creators to monetize quicker. </p>
            </div>
            <div className="col-lg-3 align-self-center ">
                    <p className="h2 ari black pt-3 px-2"> Higher Earnings Potential </p>
                    <p className="h5 ari black px-2"> Better fitting brand partners = better conversion rates = more $$$. It's a win-win for your sponsors and you. </p>
                    <p className="h2 ari black pt-3 px-2"> Low Fees </p>
                    <p className="h5 ari black px-2"> No upfront cost to use the platform. We take a tiny transaction fee when you get paid, unlike traditional ad agencies and hosting platforms.   </p>
            </div>
            <div className="col-lg-2">
                
            </div>

        </div>
    </>
                    
    )
}

export default CoreFeatures;