import React from 'react';
import '../../src/styles/main.css';

const WhoWeAre = () => {

    return (
    <>
        <div className="row who pt-5 pb-5" id="who">
            <div className="col col-lg-4 align-self-middle d-flex align-items-center">
                <p className="display-4 justify-content-center text-white text-justify-right d-block mx-auto px-2 ari"> How <br></br> Curtain <br></br>Works </p>
            </div>
            <div className="col-lg-6 align-self-center px-5">
                <div className='d-flex'>
                    <div className='h2 ari white align-self-center px-4'> 1 </div>
                    <p className="h4 ari white px-4"> Create an account and podcast bio, link your podcast streaming platforms, and (optionally) import or create your media kit. </p>
                </div>
                <div className='d-flex pt-3'>
                <div className='h2 ari white align-self-center px-4'> 2 </div>
                    <p className="h4 ari white px-4"> Use our filters to find podcast sponsors that best align your niche audience's interests and fit your desired conditions.  </p>
                </div>
                <div className='d-flex pt-3'>
                    <div className='h2 ari white align-self-center px-4'> 3 </div>
                    <p className="h4 ari white px-4"> Send an agreement request with your desired terms. When an agreement is accepted, you can connect your Stripe accounts.    </p>
                </div>
                <div className='d-flex pt-3'>
                <div className='h2 ari white align-self-center px-4'> 4 </div>
                    <p className="h4 ari white px-4"> Track how much you are making real-time from all of your sponsors across all of your podcasts. </p>
                </div>
            </div>
            <div className="col-lg-1 align-self-start px-2" style={{background: "rgb(244,162,99)"}}>
            </div>
        </div>
    </>
    )
}
export default WhoWeAre;